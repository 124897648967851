import { Dispatch } from 'react';
import { UserInterface, UserGrid } from 'store/user/types';
import { api } from '../root';

import { addNotification } from '../../store/notifications/slice';
import { handleRequestErrors } from 'helpers/functions/commons';
import { UserState } from 'store/userState/types';

export const R_getUserInfo = async () => {
    const response = await api.get<UserState>('/Account/user-info')
    return response.data
} 

export const createUser = async (credentials: UserInterface, thunkAPI: unknown) => {
    try {
        const response = await api.post<UserInterface>('/Admin/user/create', credentials);
        return response.data;
    } catch (e: unknown) {
        const validationMessage = handleRequestErrors(e)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const thunkRejectError = thunkAPI as { rejectWithValue: (message: any) => any, dispatch: Dispatch<unknown> }
        thunkRejectError.dispatch(addNotification({ type: 'error', message: validationMessage }));
        return thunkRejectError.rejectWithValue(validationMessage);
    }
}


export const getUser = async (credentials: string, thunkAPI: unknown) => {
    try {
        const response = await api.get<UserInterface>(`/user/get/${credentials}`);
        return response.data;
    } catch (e: unknown) {
        const validationMessage = handleRequestErrors(e)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const thunkRejectError = thunkAPI as { rejectWithValue: (message: any) => any, dispatch: Dispatch<unknown> }
        thunkRejectError.dispatch(addNotification({ type: 'error', message: validationMessage }));
        return thunkRejectError.rejectWithValue(validationMessage);
    }
}

export const getAllUsers = async (credentials: { pageNumber: number, pageSize: number }, thunkAPI: unknown) => {
    try {
        const response = await api.get<UserGrid>(`/Admin/user/getall/${credentials.pageNumber}`, {
            params: {
                pageSize: credentials.pageSize
            }
        });
        return response.data
    } catch (e: unknown) {
        const validationMessage = handleRequestErrors(e)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const thunkRejectError = thunkAPI as { rejectWithValue: (message: any) => any, dispatch: Dispatch<unknown> }
        thunkRejectError.dispatch(addNotification({ type: 'error', message: validationMessage }));
        return thunkRejectError.rejectWithValue(validationMessage);
    }
}

export const updateUser = async (credentials: UserInterface, thunkAPI: unknown) => {
    try {
        const response = await api.put<UserInterface>('/Admin/user/update', credentials);
        return response.data;
    } catch (e: unknown) {
        const validationMessage = handleRequestErrors(e)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const thunkRejectError = thunkAPI as { rejectWithValue: (message: any) => any, dispatch: Dispatch<unknown> }
        thunkRejectError.dispatch(addNotification({ type: 'error', message: validationMessage }));
        return thunkRejectError.rejectWithValue(validationMessage);
    }
}

export const deleteUser = async (credentials: string, thunkAPI: unknown) => {
    try {
        const response = await api.delete<UserInterface>(`/admin/user/delete/${credentials}`);
        return response.data;
    } catch (e: unknown) {
        const validationMessage = handleRequestErrors(e)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const thunkRejectError = thunkAPI as { rejectWithValue: (message: any) => any, dispatch: Dispatch<unknown> }
        thunkRejectError.dispatch(addNotification({ type: 'error', message: validationMessage }));
        return thunkRejectError.rejectWithValue(validationMessage);
    }
}

export const blockUser = async (credentials: UserInterface, thunkAPI: unknown) => {
    try {
        const response = await api.put<UserInterface>(`/admin/user/block/${credentials.id}`, {isActive: !credentials.isActive});
        return response.data;
    } catch (e: unknown) {
        const validationMessage = handleRequestErrors(e)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const thunkRejectError = thunkAPI as { rejectWithValue: (message: any) => any, dispatch: Dispatch<unknown> }
        thunkRejectError.dispatch(addNotification({ type: 'error', message: validationMessage }));
        return thunkRejectError.rejectWithValue(validationMessage);
    }
}

export const sendEmails = async (emails: string[], thunkAPI: unknown) => {
    try {
        const response = await api.post('/admin/user/invite', emails);
        return response.data;
      } catch (e: unknown) {
        const validationMessage = handleRequestErrors(e)
        const thunkRejectError = thunkAPI as { rejectWithValue: (message: any) => any, dispatch: Dispatch<unknown> }
        thunkRejectError.dispatch(addNotification({ type: 'error', message: validationMessage }));
        return thunkRejectError.rejectWithValue(validationMessage);
    }
}

export const resetUserPasswordToDefault = async (credentials: string, thunkAPI: unknown) => {
    try {
        const response = await api.put<UserInterface>('/user/delete/', credentials);
        return response.data;
    } catch (e: unknown) {
        const validationMessage = handleRequestErrors(e)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const thunkRejectError = thunkAPI as { rejectWithValue: (message: any) => any, dispatch: Dispatch<unknown> }
        thunkRejectError.dispatch(addNotification({ type: 'error', message: validationMessage }));
        return thunkRejectError.rejectWithValue(validationMessage);
    }
}