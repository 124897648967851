import { TreeViewDataItem, ICategorizeOption, IOptions, TagAttributeValue, IDropDownData, IProductionDropdownData } from './../../store/sidebar/types';
import { ITagAttribute } from 'store/sidebar/types';
import { v4 as uuidv4 } from 'uuid';
import sortAlphabetically from 'helpers/functions/sortAlphabetically';
import { datasetOptionsMapping, datasetOptionsMappingEmissions, datasetsIds } from 'helpers/constants/commons';

export const createFiltersSubMenues = (response: ITagAttribute[]) => {
    const filtersTree: TreeViewDataItem[] = [
        // { name: 'User Calculations', category: '', element: 'userCalculations', type: 'subMenu', id: uuidv4(), items: [{ items: [], element: '', type: 'title', id: uuidv4() }], expanded: false, },
    ];
    response = response.map(item=>item.group === "Emissions Data" ? {...item, identifierFieldType: 4} : item)
    response = response.filter(item=>item.group !== "No filter") // TODO: Check if this can be handled in the backend NB 2024-04-22
    response = response.filter(item=>item.category != 5) // TODO: Check filter logic here for preventing Production parameters
    
    response.forEach((f: ITagAttribute) => {
        const current = filtersTree.find((filterFromTree: TreeViewDataItem) => filterFromTree.name?.trim() === f.group?.trim())

        if (!current && f.group)
            filtersTree.push({
                name: f.group.trim(),
                category: '',
                element: f.key,
                type: 'subMenu',
                id: f.id,
                identifierFieldType: f.identifierFieldType,
                items: [],
                expanded: false
            });
    })
    
    return createFilters(sortAlphabetically(filtersTree, 'name'), response)
}

export const createFilters = (filters: TreeViewDataItem[], response: ITagAttribute[]): TreeViewDataItem[] => {
    
    response.forEach((f: ITagAttribute) => {
        const filter: TreeViewDataItem = {
            element: f.type === 1 ? 'filter' : 'numeric',
            type: f.type === 1 ? 'filter' : 'numeric',
            firstConditionList: f.type === 1 ? { id: 3, name: 'Contains', defaultPriority: 1 } : { id: 5, name: 'Is greater than', defaultPriority: 1 },
            firstConditionInput: null,
            logicalOperationList: { id: 1, name: 'And', defaultPriority: 1 },
            secondConditionList: f.type === 1 ? { id: 4, name: 'Does not contain', defaultPriority: 2 } : { id: 3, name: 'Is less than', defaultPriority: 2 },
            secondConditionInput: null,
            filterApplied: false,
            tooltip: f.description,
            items: [],
            values: [],
            id: f.id,
            name: f.name,
        };

        filters?.find((e: TreeViewDataItem) => {
            if (e.name && f.group) {
                if (e.name.trim() === f.group.trim()) {
                    const index = e.items.findIndex(k => k.id === f.id)
                    if (index > -1) {
                        e.items.splice(index, 1, filter)
                    } else {
                        e.items?.push(filter);
                    }
                };
            };
            e.items = sortAlphabetically(e.items, 'name');
        });
    })

    // TODO: Review Add Basics logic. This is not required in Categorise By tree. NB 2024-04-22
    const basicsIndex = filters.findIndex(item => item.identifierFieldType !== 1 && 
        item.identifierFieldType !== 2 && 
        item.identifierFieldType !== 4 && 
        item.identifierFieldType !== 8)
    let basics
    if (basicsIndex > -1) {
        basics = filters[basicsIndex]
        const addBasic: TreeViewDataItem[] = response.filter(item => !(datasetsIds.includes(item.identifierFieldType as number)) || item.identifierFieldType as number == 15).map(f => (
            {
                element: f.type === 1 ? 'filter' : 'numeric',
                type: f.type === 1 ? 'filter' : 'numeric',
                firstConditionList: f.type === 1 ? { id: 3, name: 'Contains', defaultPriority: 1 } : { id: 5, name: 'Is greater than', defaultPriority: 1 },
                firstConditionInput: null,
                logicalOperationList: { id: 1, name: 'And', defaultPriority: 1 },
                secondConditionList: f.type === 1 ? { id: 4, name: 'Does not contain', defaultPriority: 2 } : { id: 3, name: 'Is less than', defaultPriority: 2 },
                secondConditionInput: null,
                filterApplied: false,
                tooltip: f.description,
                unit: f.unitOfMeasurement,
                items: [],
                values: [],
                id: f.id,
                name: f.name,
            }
        ))
        basics.items = [...addBasic]
        filters.splice(basicsIndex, 1)
    }


    const result: TreeViewDataItem[] = [];

    // Create a map to group items by 'identifierFieldType'
    const groupedMap = new Map();
    datasetsIds.filter(item => item != 5).forEach(item => groupedMap.set(item, [])) // Filter removes 'Common Data'

    filters.forEach(item => {
        const { name, id, identifierFieldType } = item;
        groupedMap.forEach((items, key, map) => {
            
            if (identifierFieldType === key) {
                const keyToStore = key
                if (!groupedMap.has(key)) {
                    groupedMap.set(key, []);
                }
                groupedMap.get(keyToStore).push(item);
            }
        })
    });

    // Convert the map into the desired format
    groupedMap.forEach((items, key: number, map) => {
        if (datasetOptionsMappingEmissions[key as keyof typeof datasetOptionsMappingEmissions])
            result.push({
                name: datasetOptionsMappingEmissions[key as keyof typeof datasetOptionsMappingEmissions],
                category: '',
                element: datasetOptionsMappingEmissions[key as keyof typeof datasetOptionsMappingEmissions],
                type: 'subMenu',
                id: result.length.toString(),
                items: items,
                expanded: false,
            });
    });
    const final: TreeViewDataItem[] = result
    if (basics) {
        final.unshift(basics)
    }

    if (final.length) {
        return final
    } else {
        return filters
    }
};

export const creteNumericCategorizeOptions = (data: ITagAttribute[]): ICategorizeOption[] => {
    const numericOptions: ICategorizeOption[] = [];

    data.forEach((f: ITagAttribute) => {
        numericOptions.push({
            name: f.name,
            id: f.id,
            value: f.value,
            type: 'numeric',
        })
    })

    return numericOptions
}

export const creteStringCategorizeOptions = (data: ITagAttribute[]): ICategorizeOption[] => {
  const stringOptions: ICategorizeOption[] = []

  data = data.filter(item=>item.group !== "No filter")

  data.forEach((f: ITagAttribute) => {
    stringOptions.push({
      name: f.name,
      id: f.id,
      value: f.value,
      type: "filter",
    })
  })

  return stringOptions
}

export const processQucikPlots = (data: { quickPlots: { id: string; title: string, type: number }[]; }): Array<IOptions> => {
    return data.quickPlots.map((item: { id: string; title: string; type: number }) => {
        return {
            id: item.id,
            name: item.title,
            type: item.type,
        }
    })
}


export const createDropdownModel = (data: TagAttributeValue[]) => 
    data.map((e: TagAttributeValue) => ({
    name: e.name,
    id: e.id,
    value: e.value || e.name,
    key: e.key,
    identifierFieldType: datasetOptionsMapping[e.identifierFieldType as keyof typeof datasetOptionsMapping],
    identifierFieldTypeKey: e.identifierFieldType,
    unitOfMeasurement: e.unitOfMeasurement
}))

export const createDropdownModelProduction = (data: (IProductionDropdownData)[]) => data.map((e: IProductionDropdownData) => ({
    name: e.name,
    id: e.id,
    value: e.value || e.name,
    key: e.key,
    labelName: e.labelName,
    unitOfMeasurement: e.unitOfMeasurement,
    type: e.type,
    identifierFieldType: datasetOptionsMapping[e.type as keyof typeof datasetOptionsMapping],
    identifierFieldTypeKey: e.type
}))

export const processProductionPlotIndentifierOptions = (data: (IDropDownData & { labelName: string, identifierFieldId: string })[]): (IDropDownData & { labelName: string, id?: string })[] => data.map(item => ({ ...item, id: item.identifierFieldId }))