import { IBenchmarkPlotOptions, ICategorizeOption, IHistogramOptions, IProductionOptions, PlotOptions } from '../../store/sidebar/types';
import { Dispatch } from 'react';
import { ITagAttribute, IFilter, TreeViewDataItem } from 'store/sidebar/types';
import { api } from '../root';
import { addNotification } from '../../store/notifications/slice';
import { createDropdownModel, createDropdownModelProduction, createFiltersSubMenues, creteNumericCategorizeOptions, creteStringCategorizeOptions, processProductionPlotIndentifierOptions, processQucikPlots } from './processors';
import { handleRequestErrors } from 'helpers/functions/commons';

export const getSideBarFilters = async (tagAttributes: ITagAttribute[], thunkAPI: unknown) => {
    try {

        const responseString = tagAttributes.filter(item => item.type === 1);
        const responseNumeric = tagAttributes.filter(item => item.type === 2);

        const filtersTree: TreeViewDataItem[] = createFiltersSubMenues([...responseString, ...responseNumeric]);
        const numericOptions: ICategorizeOption[] = creteNumericCategorizeOptions(responseNumeric);
        const stringOptions: ICategorizeOption[] = creteStringCategorizeOptions(responseString);

        return { filtersTree, numericOptions, stringOptions }
    } catch (e: unknown) {
        const validationMessage = handleRequestErrors(e)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const thunkRejectError = thunkAPI as { rejectWithValue: (message: any) => any, dispatch: Dispatch<unknown> }
        thunkRejectError.dispatch(addNotification({ type: 'error', message: validationMessage }));
        return thunkRejectError.rejectWithValue(validationMessage);
    }
}

export const GetTagAttributeAllowedValues = async (filter: { tagAttributeId: string, filters: IFilter[], workspaceId: string }, thunkAPI: unknown) => {
    try {
        const response = await api.get<IFilter[]>(`/Workspace/${filter.workspaceId}/AllowedValues`, { params: { TagAttributeId: filter.tagAttributeId } });
        return response.data
    } catch (e: unknown) {
        const validationMessage = handleRequestErrors(e)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const thunkRejectError = thunkAPI as { rejectWithValue: (message: any) => any, dispatch: Dispatch<unknown> }
        thunkRejectError.dispatch(addNotification({ type: 'error', message: validationMessage }));
        return thunkRejectError.rejectWithValue(validationMessage);
    }
}

export const getStringOperations = async (thunkAPI: unknown) => {
    try {
        const response = await api.get('/Workspace/Plot/GetStringOperations');
        return response.data
    } catch (e: unknown) {
        const validationMessage = handleRequestErrors(e)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const thunkRejectError = thunkAPI as { rejectWithValue: (message: any) => any, dispatch: Dispatch<unknown> }
        thunkRejectError.dispatch(addNotification({ type: 'error', message: validationMessage }));
        return thunkRejectError.rejectWithValue(validationMessage);
    }
}

export const getNumericOperations = async (thunkAPI: unknown) => {
    try {
        const response = await api.get('/Workspace/Plot/GetNumericOperations');
        return response.data
    } catch (e: unknown) {
        const validationMessage = handleRequestErrors(e)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const thunkRejectError = thunkAPI as { rejectWithValue: (message: any) => any, dispatch: Dispatch<unknown> }
        thunkRejectError.dispatch(addNotification({ type: 'error', message: validationMessage }));
        return thunkRejectError.rejectWithValue(validationMessage);
    }
}

export const getBooleanOperations = async (thunkAPI: unknown) => {
    try {
        const response = await api.get('/Workspace/Plot/GetBooleanOperations');
        return response.data
    } catch (e: unknown) {
        const validationMessage = handleRequestErrors(e)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const thunkRejectError = thunkAPI as { rejectWithValue: (message: any) => any, dispatch: Dispatch<unknown> }
        thunkRejectError.dispatch(addNotification({ type: 'error', message: validationMessage }));
        return thunkRejectError.rejectWithValue(validationMessage);
    }
}

export const getPlotOptions = async ({ workspaceId, plotId }: { workspaceId: string, plotId: string }, thunkAPI: unknown) => {
    try {
        const response = await api.get(`/Workspace/${workspaceId}/CrossPlot/${plotId}`);
        return response.data;
    } catch (e: unknown) {
        const validationMessage = handleRequestErrors(e)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const thunkRejectError = thunkAPI as { rejectWithValue: (message: any) => any, dispatch: Dispatch<unknown> }
        thunkRejectError.dispatch(addNotification({ type: 'error', message: validationMessage }));
        return thunkRejectError.rejectWithValue(validationMessage);
    }
}

export const getBenchmarkPlotOptions = async ({ workspaceId, plotId }: { workspaceId: string, plotId: string }, thunkAPI: unknown) => {
    try {
        const response = await api.get(`/Workspace/${workspaceId}/BenchmarkPlot/${plotId}`);
        return response.data;
    } catch (e: unknown) {
        const validationMessage = handleRequestErrors(e)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const thunkRejectError = thunkAPI as { rejectWithValue: (message: any) => any, dispatch: Dispatch<unknown> }
        thunkRejectError.dispatch(addNotification({ type: 'error', message: validationMessage }));
        return thunkRejectError.rejectWithValue(validationMessage);
    }
}

export const getHistogramOptions = async ({ workspaceId, plotId }: { workspaceId: string, plotId: string }, thunkAPI: unknown) => {
    try {
        const response = await api.get(`/Workspace/${workspaceId}/Histogram/${plotId}`);
        return response.data;
    } catch (e: unknown) {
        const validationMessage = handleRequestErrors(e)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const thunkRejectError = thunkAPI as { rejectWithValue: (message: any) => any, dispatch: Dispatch<unknown> }
        thunkRejectError.dispatch(addNotification({ type: 'error', message: validationMessage }));
        return thunkRejectError.rejectWithValue(validationMessage);
    }
}



export const getCategory1TagAttributesValues = async (unit: number, thunkAPI: unknown) => {
    
    try {
        const response = await api.get(`/TagAttribute/GetAll`, { params: { category: 1, fieldUnit: unit } });
        const data = createDropdownModel(response.data);
        return data;
    } catch (e: unknown) {
        const validationMessage = handleRequestErrors(e)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const thunkRejectError = thunkAPI as { rejectWithValue: (message: any) => any, dispatch: Dispatch<unknown> }
        thunkRejectError.dispatch(addNotification({ type: 'error', message: validationMessage }));
        return thunkRejectError.rejectWithValue(validationMessage);
    }
}

export const getProductionTagAttributesValues = async (unit: number, thunkAPI: unknown) => {
    try {
        const response = await api.get(`/TagAttribute/GetAll`, { params: { category: 5, fieldType: 1, fieldUnit: unit } });
        const data = createDropdownModelProduction(response.data);
        return data;
    } catch (e: unknown) {
        const validationMessage = handleRequestErrors(e)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const thunkRejectError = thunkAPI as { rejectWithValue: (message: any) => any, dispatch: Dispatch<unknown> }
        thunkRejectError.dispatch(addNotification({ type: 'error', message: validationMessage }));
        return thunkRejectError.rejectWithValue(validationMessage);
    }
}


export const getNumericTagAttributesValues = async (unit: number, thunkAPI: unknown) => {
    try {
        const response = await api.get(`/TagAttribute/GetAll`, { params: { category: 1, type: 2, fieldUnit: unit } });
        const data = createDropdownModel(response.data);
        return data;
    } catch (e: unknown) {
        const validationMessage = handleRequestErrors(e)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const thunkRejectError = thunkAPI as { rejectWithValue: (message: any) => any, dispatch: Dispatch<unknown> }
        thunkRejectError.dispatch(addNotification({ type: 'error', message: validationMessage }));
        return thunkRejectError.rejectWithValue(validationMessage);
    }
}


export const getQuickPlots = async (thunkAPI: unknown) => {
    try {
        const { data } = await api.get('QuickPlot/GetAll')
        return processQucikPlots(data)
    } catch (e) {
        const validationMessage = handleRequestErrors(e)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const thunkRejectError = thunkAPI as { rejectWithValue: (message: any) => any, dispatch: Dispatch<unknown> }
        thunkRejectError.dispatch(addNotification({ type: 'error', message: validationMessage }));
        return thunkRejectError.rejectWithValue(validationMessage);
    }
}

export const saveQuickPlot = async (plotId: string, thunkAPI: unknown) => {
    try {
        const { data } = await api.post('QuickPlot', { plotId })
        return data
    } catch (e) {
        const validationMessage = handleRequestErrors(e)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const thunkRejectError = thunkAPI as { rejectWithValue: (message: any) => any, dispatch: Dispatch<unknown> }
        thunkRejectError.dispatch(addNotification({ type: 'error', message: validationMessage }));
        return thunkRejectError.rejectWithValue(validationMessage);
    }
}

export const setQuickPlot = async (payload: { workspaceId: string, plotId: string, quickPlotId: string }) => {
    try {
        const { data } = await api.put(`/Workspace/${payload.workspaceId}/Plot/${payload.plotId}/ApplyQuickPlot`, {
            quickPlotId: payload.quickPlotId
        })

        return data
    } catch (e) {
        console.error(e)
    }
}

export const deleteQuickPlot = async (plotId: string) => {
    try {
        const { data } = await api.delete(`QuickPlot/${plotId}`)
        return data
    } catch (e) {
        console.error(e)
    }
}

export const saveSidebar = async (data: { plotOptions: PlotOptions, workspaceId: string, plotId: string }, thunkAPI: unknown) => {
    try {
        await api.put(`/Workspace/${data.workspaceId}/CrossPlot/${data.plotId}`, data.plotOptions);
        return data.plotId;
    } catch (e: unknown) {
        const validationMessage = handleRequestErrors(e)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const thunkRejectError = thunkAPI as { rejectWithValue: (message: any) => any, dispatch: Dispatch<unknown> }
        thunkRejectError?.dispatch(addNotification({ type: 'error', message: validationMessage }));
        return thunkRejectError?.rejectWithValue(validationMessage) || { errorMsg: validationMessage };
    }
}

export const saveSidebarBenchmarkOptions = async (data: { plotOptions: IBenchmarkPlotOptions, workspaceId: string, plotId: string }, thunkAPI: unknown) => {
    try {
        const body = {
            benchmarkPlotOptions: data.plotOptions.benchmarkPlotOptions,
            benchmarkId: data.plotOptions.benchmark?.id
        }
        await api.put(`/Workspace/${data.workspaceId}/BenchmarkPlot/${data.plotId}`, body);
        return data.plotId;
    } catch (e: unknown) {
        const validationMessage = handleRequestErrors(e)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const thunkRejectError = thunkAPI as { rejectWithValue: (message: any) => any, dispatch: Dispatch<unknown> }
        thunkRejectError?.dispatch(addNotification({ type: 'error', message: validationMessage }));
        return thunkRejectError?.rejectWithValue(validationMessage) || { errorMsg: validationMessage };
    }
}

export const saveSidebarHistogramOptions = async (data: { plotOptions: IHistogramOptions, workspaceId: string, plotId: string }, thunkAPI: unknown) => {
    try {
        await api.put(`/Workspace/${data.workspaceId}/Histogram/${data.plotId}`, data.plotOptions);
        return data.plotId;
    } catch (e: unknown) {
        const validationMessage = handleRequestErrors(e)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const thunkRejectError = thunkAPI as { rejectWithValue: (message: any) => any, dispatch: Dispatch<unknown> }
        thunkRejectError?.dispatch(addNotification({ type: 'error', message: validationMessage }));
        return thunkRejectError?.rejectWithValue(validationMessage) || { errorMsg: validationMessage };
    }
}

export const R_getProductionPlotOptions = async (data: { workspaceId: string, plotId?: string }, thunkAPI: unknown) => {
    try {
        const response = await api.get(`/Workspace/${data.workspaceId}/ProductionPlot/${data.plotId}`);
        return response.data;
    } catch (e: unknown) {
        const validationMessage = handleRequestErrors(e)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const thunkRejectError = thunkAPI as { rejectWithValue: (message: any) => any, dispatch: Dispatch<unknown> }
        thunkRejectError?.dispatch(addNotification({ type: 'error', message: validationMessage }));
        return thunkRejectError?.rejectWithValue(validationMessage);
    }
}

export const R_getProductionPlotIndentifierOptions = async (data: { workspaceId: string, ids: string[] }, thunkAPI: unknown) => {
    try {
        const response = await api.post(`/Workspace/${data.workspaceId}/ProductionPlotIdentifiers`, data.ids);
        const processed = processProductionPlotIndentifierOptions(response.data)
        return processed;
    } catch (e: unknown) {
        const validationMessage = handleRequestErrors(e)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const thunkRejectError = thunkAPI as { rejectWithValue: (message: any) => any, dispatch: Dispatch<unknown> }
        thunkRejectError?.dispatch(addNotification({ type: 'error', message: validationMessage }));
        return thunkRejectError?.rejectWithValue(validationMessage);
    }
}

export const R_saveProductionPlotOptions = async (data: { workspaceId: string, plotId?: string, productionPlotOptions: IProductionOptions }, thunkAPI: unknown) => {
    try {
        const response = await api.put(`/Workspace/${data.workspaceId}/ProductionPlot/${data.plotId}`, { ...data.productionPlotOptions, workspaceId: data.workspaceId, tags: undefined });
        return response;
    } catch (e: unknown) {
        const validationMessage = handleRequestErrors(e)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const thunkRejectError = thunkAPI as { rejectWithValue: (message: any) => any, dispatch: Dispatch<unknown> }
        thunkRejectError?.dispatch(addNotification({ type: 'error', message: validationMessage }));
        return thunkRejectError?.rejectWithValue(validationMessage);
    }
}

export const selectQuickPlot = async (quickPlotId: string) => {
    try {
        const { data } = await api.get(`QuickPlot/${quickPlotId}`)
        return data
    } catch (e) {
        console.error(e)
    }
}


export const R_addFilterSet = async (data: {workspaceId: string, title: string}, thunkAPI: unknown) => {
    try {
        const res = await api.post(`/Workspace/${data.workspaceId}/FilterSet`, {title: data.title})
        return res.data
    } catch (e: unknown) {
        const validationMessage = handleRequestErrors(e)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const thunkRejectError = thunkAPI as { rejectWithValue: (message: any) => any, dispatch: Dispatch<unknown> }
        thunkRejectError?.dispatch(addNotification({ type: 'error', message: validationMessage }));
        return thunkRejectError?.rejectWithValue(validationMessage);
    }
}

export const R_getFilterSets = async (data: {workspaceId: string}, thunkAPI: unknown) => {
    try {
        const res = await api.get(`/Workspace/${data.workspaceId}/FilterSets`)
        return res.data
    } catch (e: unknown) {
        const validationMessage = handleRequestErrors(e)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const thunkRejectError = thunkAPI as { rejectWithValue: (message: any) => any, dispatch: Dispatch<unknown> }
        thunkRejectError?.dispatch(addNotification({ type: 'error', message: validationMessage }));
        return thunkRejectError?.rejectWithValue(validationMessage);
    }
}

export const R_deleteFilterSet = async (data: {workspaceId: string, filterSetId: string}, thunkAPI: unknown) => {
    try {
        const res = await api.delete(`/Workspace/${data.workspaceId}/FilterSet/${data.filterSetId}`)
        return res.data
    } catch (e: unknown) {
        const validationMessage = handleRequestErrors(e)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const thunkRejectError = thunkAPI as { rejectWithValue: (message: any) => any, dispatch: Dispatch<unknown> }
        thunkRejectError?.dispatch(addNotification({ type: 'error', message: validationMessage }));
        return thunkRejectError?.rejectWithValue(validationMessage);
    }
}

export const R_clearFilterSetSelection = async (data: {workspaceId: string}, thunkAPI: unknown) => {
    try {
        const res = await api.patch(`/Workspace/${data.workspaceId}/FilterSet/ClearSelection`)
        return res.data
    } catch (e: unknown) {
        const validationMessage = handleRequestErrors(e)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const thunkRejectError = thunkAPI as { rejectWithValue: (message: any) => any, dispatch: Dispatch<unknown> }
        thunkRejectError?.dispatch(addNotification({ type: 'error', message: validationMessage }));
        return thunkRejectError?.rejectWithValue(validationMessage);
    }
}

export const R_changeFilterSet = async (data: {workspaceId: string, filterSetId: string}, thunkAPI: unknown) => {
    try {
        const res = await api.patch(`/Workspace/${data.workspaceId}/FilterSet/${data.filterSetId}`)
        return res.data
    } catch (e: unknown) {
        const validationMessage = handleRequestErrors(e)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const thunkRejectError = thunkAPI as { rejectWithValue: (message: any) => any, dispatch: Dispatch<unknown> }
        thunkRejectError?.dispatch(addNotification({ type: 'error', message: validationMessage }));
        return thunkRejectError?.rejectWithValue(validationMessage);
    }
}