import React, { useState } from "react";
import './MapOptions.scss';
import { DropDown, Tooltip } from "components/SideBarItems/DropDown";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { KendoCheckbox } from "components/SideBarItems/Checkbox";
import { IDropDownData } from "store/sidebar/types";
import { saveMapOptionsThunk } from "store/workspace/thunk";
import { getMapViewFilteredPointsThunk } from "store/mapView/thunk";
import axios, { CancelTokenSource } from "axios";
import { IElementType } from "components/SideBarItems/types";
import { workspaceSlice } from "store/workspace/slice";
import sortAlphabetically from 'helpers/functions/sortAlphabetically';
import { LICENSE_TYPES } from "helpers/constants/app";
import WithRole from "components/userRoles/withRole/WithRole";
import { TooltipTreeFilter } from "components/SideBarItems/DropDownTreeFilter";

export const MapOptions: React.FC = () => {
    const dispatch = useAppDispatch();
    const mapEndpointSource = React.useRef<CancelTokenSource | null>(null)
    const { workspaceId, mapOptions } = useAppSelector(state => state.workspaceSlice);
    const { category1TagAttributesValues } = useAppSelector(state => state.sideBarTreeSlice);
    const { userDataLegends } = useAppSelector(state => state.mapViewSlice.mapViewData);
    const [opened, setOpened] = useState<boolean>(true);
    const mapTypes: IDropDownData[] = [
        { name: 'Light', value: 'mapbox://styles/mapbox/light-v11', id: "1" },
        { name: 'Outdoors', value: 'mapbox://styles/mapbox/outdoors-v12', id: "2" },
        { name: 'Satellite', value: 'mapbox://styles/mapbox/satellite-v9', id: "3" },
        { name: 'Streets', value: 'mapbox://styles/mapbox/streets-v12', id: "4" },
    ];

    const handleChangeValue = (obj: { element: IElementType, value: string, group: string | null, index?: number }): void => {
        dispatch(workspaceSlice.actions.setMapOptionItem(obj.element === 'mapType' ? { ...obj, value: +obj.value } : obj));
    };

    const handleSaveValues = async (_?: IElementType, group?: string | null) => {
        await dispatch(saveMapOptionsThunk({ id: workspaceId, mapOptions }));
        if (group === 'serverSideOptions') {
            if (mapEndpointSource.current) mapEndpointSource.current.cancel()
            mapEndpointSource.current = axios.CancelToken.source();
            await dispatch(getMapViewFilteredPointsThunk({ workspaceId, source: mapEndpointSource.current }));
        }
    };

    return (
      <div className="mapOptions">
        <div className="contentVisibilityHandler">
          <h3 onClick={() => setOpened(!opened)} style={{ cursor: "pointer" }}>
            <span className={opened ? "k-icon k-i-caret-alt-down" : "k-icon k-i-caret-alt-right"}></span>Map Options
          </h3>
        </div>
        <div className={opened ? "content" : "content closed"}>
          <h4>Map Type</h4>
          <DropDown
            items={mapTypes}
            label=""
            element="mapType"
            value={mapOptions.mapType > -1 ? mapOptions.mapType + "" : "3"}
            setFunction={handleChangeValue}
            saveFunction={handleSaveValues}
            group={""}
          />
          <h4>Labels</h4>
          <KendoCheckbox label="User Data" element="showUserData" disabled={!userDataLegends.length} value={mapOptions.showUserData} setFunction={handleChangeValue} saveFunction={handleSaveValues} />
          <WithRole roles={[LICENSE_TYPES.global]}>
            <KendoCheckbox
              label="Global Database"
              element="showGlobalDatabase"
              value={mapOptions.showGlobalDatabase}
              setFunction={(newValue) => {
                handleChangeValue(newValue)
              }}
              saveFunction={(newValue) => {
                handleSaveValues(newValue)
              }}
            />
          </WithRole>
          <h4>Legends</h4>
          <KendoCheckbox label="Filters" element="showFilters" value={mapOptions.showFilters} setFunction={handleChangeValue} saveFunction={handleSaveValues} />
          <KendoCheckbox
            label="User Data"
            element="showUserDataLegends"
            value={mapOptions.showUserDataLegends}
            disabled={!userDataLegends.length}
            setFunction={handleChangeValue}
            saveFunction={handleSaveValues}
          />
          {/* <h4>Layers</h4>
          <KendoCheckbox
            label="Subsurface Assets"
            element="subsurfaceAssets"
            group="serverSideOptions"
            value={mapOptions.subsurfaceAssets}
            setFunction={handleChangeValue}
            saveFunction={handleSaveValues}
          />
          <WithRole roles={[LICENSE_TYPES.global]}>
            <KendoCheckbox label="CCS Projects" element="ccsProjects" group="serverSideOptions" value={mapOptions.ccsProjects} setFunction={handleChangeValue} saveFunction={handleSaveValues} />
          </WithRole>
          <WithRole roles={[LICENSE_TYPES.global]}>
            <KendoCheckbox label="CO2 Emitters" element="cO2Emitters" group="serverSideOptions" value={mapOptions.cO2Emitters} setFunction={handleChangeValue} saveFunction={handleSaveValues} />
          </WithRole>
          <WithRole roles={[LICENSE_TYPES.global]}>
            <KendoCheckbox
              label="Infrastructure Assets"
              element="globalPipelineNetworks"
              group="serverSideOptions"
              value={mapOptions.globalPipelineNetworks}
              setFunction={handleChangeValue}
              saveFunction={handleSaveValues}
            />
          </WithRole> */}
          <h4>Tooltips</h4>
          {new Array(5).fill(null).map((item, i) => (
            <TooltipTreeFilter
              key={i}
              items={sortAlphabetically(category1TagAttributesValues, "name")}
              element="tooltips"
              index={i}
              group="serverSideOptions"
              value={mapOptions.tooltips[i]}
              setFunction={handleChangeValue}
              saveFunction={handleSaveValues}
            />
          ))}
        </div>
      </div>
    )
}

