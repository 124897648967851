import { IWorkspace } from '../../store/workspace/types';
import { ISaveModel, ITab } from './types';
import { IPlot } from 'store/workspace/types';
import permVsPoro from '../../assets/icons/permVsPoro.svg';
import { TreeViewDataItem } from 'store/sidebar/types';
import { v4 as uuidv4 } from 'uuid';
import MapIcon from '../../assets/icons/map.svg';
import { iconMapping } from 'helpers/constants/dataSetIconMapping';

export const createTabs = (workspace: IWorkspace, tabId?: string, selectLast?: boolean): {tabs: ITab[] } => {
    // /////  This code need to revised todo
    const tabs = workspace.plots.map((plot: IPlot, i, arr) => {
        const tab: ITab = {
            id: plot.id,
            parentId: plot.identifierFieldId,
            workspaceId: workspace.id,
            defaultOption: false,
            icon: iconMapping[plot?.type as keyof typeof iconMapping] || permVsPoro,
            title: plot.title,
            type: plot.type,
            isSelected: (!!tabId && !!plot.id) ? (tabId === plot.id) : !!selectLast && (i === arr.length - 1),
            xAxis: '',// plot.title.split(' vs ')[0] || 'X',
            xTagAttributeId: plot.xTagAttributeId,
            yAxis: '',// plot.title.split(' vs ')[1] || 'Y',
            yTagAttributeId: plot.yTagAttributeId,
            quickPlotId: plot.quickPlotId
        };

        return tab
    })
    const haveSelected = tabs.filter(item=>item.isSelected)
    tabs.unshift({
        id: uuidv4(),
        workspaceId: workspace.id,
        defaultOption: true,
        icon: MapIcon,
        title: 'Map View',
        type: 'mapView',
        isSelected: haveSelected.length === 0,
        xAxis: '',
        xTagAttributeId: '',
        yAxis: '',
        yTagAttributeId: '',
        quickPlotId: null
    })

    return { tabs }
};

export const createSaveModel = (data: { plotOptions: TreeViewDataItem | undefined, selectedTab: ITab | undefined}): ISaveModel => {
    const { plotOptions, selectedTab } = data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const model: ISaveModel | any = {
        title: selectedTab?.title || '',
        type: 1,
        xTagAttributeId: selectedTab?.xTagAttributeId || '',
        yTagAttributeId: selectedTab?.yTagAttributeId || '',
        quickPlotId: selectedTab?.quickPlotId || null,
        plotOptions: {
            axisOptions: {
                xMin: null,
                xMax: null,
                yMin: null,
                yMax: null,
                bins: null,
                statsPrecision: null,
                xGridlines: false,
                yGridlines: false,
                xLogarithmic: false,
                yLogarithmic: false,
                xInvert: false,
                yInvert: false
            },
            regressions: {
                linRegression: false,
                logRegression: false,
                expRegression: false,
                powRegression: false,
                intcp: false,
                intcpValue: null,
                addPoint: false,
                xValue: null,
                label: ''
            },
            legends: {
                showFilters: false,
                showUserData: false,
                showRegressionEquations: false
            },
            labels: {
                showUserDataLabels: false,
                showUserBackgroundLabels: false,
                globalDatabaseLabels: false,
                showAxisLabels: false
            },
            tooltips: []
        }
    }

    plotOptions?.items.forEach((option: TreeViewDataItem) => {
        if (option.category !== 'plotOption' && option.type !== 'subTitle' && option.element !== 'tooltip') {
            if (option.category) {
                switch (option.type) {
                    case 'checkbox':
                        model.plotOptions[option.category][option.element] = option.checked
                        break;
                        case 'input':
                        model.plotOptions[option.category][option.element] = option.inputValue === '' ? null : option.inputValue
                        break
                    default:
                        break;
                }
            }
        }
    })
    
    return model
};