import { deleteQuickPlot, getBenchmarkPlotOptions, getBooleanOperations, getCategory1TagAttributesValues, getHistogramOptions, getNumericOperations, getNumericTagAttributesValues, getPlotOptions, getProductionTagAttributesValues, getQuickPlots, getSideBarFilters, getStringOperations, GetTagAttributeAllowedValues, R_addFilterSet, R_changeFilterSet, R_deleteFilterSet, R_getFilterSets, R_getProductionPlotIndentifierOptions, R_getProductionPlotOptions, R_saveProductionPlotOptions, saveQuickPlot, saveSidebar, saveSidebarBenchmarkOptions, saveSidebarHistogramOptions, selectQuickPlot, setQuickPlot } from '../../api/sidebar/sideBarService';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { IBenchmarkPlotOptions, IFilter, IHistogramOptions, IProductionOptions, ITagAttribute, PlotOptions } from './types';
import { ITreeElement } from '../../helpers/types/tree';
import { api } from 'api/root';
import { R_getProjectViewOptions } from 'api/projectView/projectViewService';
import { workspaceSlice } from 'store/workspace/slice';
import { MAPPINGS } from 'api/styleSettings/processors';

export const getSideBarFiltersThunk = createAsyncThunk('sideBar/getFilters', async ({ tagAttributes }: { tagAttributes: ITagAttribute[] }, thunkAPI) => {
  const response = await getSideBarFilters(tagAttributes, thunkAPI);
  await thunkAPI.dispatch(getBooleanOperationsThunk());
  await thunkAPI.dispatch(getNumericOperationsThunk());
  await thunkAPI.dispatch(getStringOperationsThunk());
  return response;
});

export const GetTagAttributeAllowedValuesThunk = createAsyncThunk('sideBar/GetTagAttributeAllowedValues', async (filter: { tagAttributeId: string, filters: IFilter[], workspaceId: string, props: ITreeElement }, thunkAPI) => {
  const response = await GetTagAttributeAllowedValues(filter, thunkAPI);
  return response;
});

export const getStringOperationsThunk = createAsyncThunk('sideBar/getStringOperations', async (thunkAPI) => {
  const response = await getStringOperations(thunkAPI);
  return response;
});

export const getNumericOperationsThunk = createAsyncThunk('sideBar/getNumericOperations', async (thunkAPI) => {
  const response = await getNumericOperations(thunkAPI);
  return response;
});

export const getBooleanOperationsThunk = createAsyncThunk('sideBar/getBooleanOperations', async (thunkAPI) => {
  const response = await getBooleanOperations(thunkAPI);
  return response;
});

export const TH_GetAllTypes = createAsyncThunk(
  'ccs/allTags',
  async () => {
    try {
      const { data: response } = await api.get<ITagAttribute[]>('/TagAttribute/GetAll');
      return response
    } catch (error) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (error?.response?.status === 500 && error?.response?.data?.errors?.InternalError?.[0] === "Client database not specified") {
        window.location.reload()
      }

    }
  }
)

export const getPlotOptionsThunk = createAsyncThunk('sideBar/getPlotOptions', async ({ workspaceId, plotId }: { workspaceId: string, plotId: string }, thunkAPI) => {
    const response = await getPlotOptions({ workspaceId, plotId }, thunkAPI);
    if(response.plotAxisTitleOptions){
      thunkAPI.dispatch(workspaceSlice.actions.setAxisStyleSettings({
        title: {font: {name: response.plotAxisTitleOptions?.Title?.font || "Arial", family: response.plotAxisTitleOptions?.Title?.font || "Arial"}, sizeFont: MAPPINGS.fontSizes[response.plotAxisTitleOptions?.Title?.fontSizeType as keyof typeof MAPPINGS.fontSizes] || MAPPINGS.fontSizes[18]},
        y: {font: {name: response.plotAxisTitleOptions?.YAxis?.font || "Arial", family: response.plotAxisTitleOptions?.YAxis?.font || "Arial"}, sizeFont: MAPPINGS.fontSizes[response.plotAxisTitleOptions?.YAxis?.fontSizeType as keyof typeof MAPPINGS.fontSizes] || MAPPINGS.fontSizes[14]},
        x: {font: {name: response.plotAxisTitleOptions?.XAxis?.font || "Arial", family: response.plotAxisTitleOptions?.XAxis?.font || "Arial"}, sizeFont: MAPPINGS.fontSizes[response.plotAxisTitleOptions?.XAxis?.fontSizeType as keyof typeof MAPPINGS.fontSizes] || MAPPINGS.fontSizes[14]},
      }))
    }
    
    return response; 
});

export const getBenchmarkPlotOptionsThunk = createAsyncThunk('sideBar/getBenchmarkPlotOptions', async ({ workspaceId, plotId }: { workspaceId: string, plotId: string }, thunkAPI) => {
  const response = await getBenchmarkPlotOptions({ workspaceId, plotId }, thunkAPI);
  if(response.plotAxisTitleOptions){
    thunkAPI.dispatch(workspaceSlice.actions.setAxisStyleSettings({
      title: {font: {name: response.plotAxisTitleOptions?.Title?.font || "Arial", family: response.plotAxisTitleOptions?.Title?.font || "Arial"}, sizeFont: MAPPINGS.fontSizes[response.plotAxisTitleOptions?.Title?.fontSizeType as keyof typeof MAPPINGS.fontSizes] || MAPPINGS.fontSizes[18]},
      y: {font: {name: response.plotAxisTitleOptions?.YAxis?.font || "Arial", family: response.plotAxisTitleOptions?.YAxis?.font || "Arial"}, sizeFont: MAPPINGS.fontSizes[response.plotAxisTitleOptions?.YAxis?.fontSizeType as keyof typeof MAPPINGS.fontSizes] || MAPPINGS.fontSizes[14]},
      x: {font: {name: response.plotAxisTitleOptions?.XAxis?.font || "Arial", family: response.plotAxisTitleOptions?.XAxis?.font || "Arial"}, sizeFont: MAPPINGS.fontSizes[response.plotAxisTitleOptions?.XAxis?.fontSizeType as keyof typeof MAPPINGS.fontSizes] || MAPPINGS.fontSizes[14]},
    }))
  }
  
  return response;
});

export const getHistogramOptionsThunk = createAsyncThunk('sideBar/getHistogramOptions', async ({ workspaceId, plotId }: { workspaceId: string, plotId: string }, thunkAPI) => {
  const response = await getHistogramOptions({ workspaceId, plotId }, thunkAPI);
  if(response.plotAxisTitleOptions){
    thunkAPI.dispatch(workspaceSlice.actions.setAxisStyleSettings({
      title: {font: {name: response.plotAxisTitleOptions?.Title?.font || "Arial", family: response.plotAxisTitleOptions?.Title?.font || "Arial"}, sizeFont: MAPPINGS.fontSizes[response.plotAxisTitleOptions?.Title?.fontSizeType as keyof typeof MAPPINGS.fontSizes] || MAPPINGS.fontSizes[18]},
      y: {font: {name: response.plotAxisTitleOptions?.YAxis?.font || "Arial", family: response.plotAxisTitleOptions?.YAxis?.font || "Arial"}, sizeFont: MAPPINGS.fontSizes[response.plotAxisTitleOptions?.YAxis?.fontSizeType as keyof typeof MAPPINGS.fontSizes] || MAPPINGS.fontSizes[14]},
      x: {font: {name: response.plotAxisTitleOptions?.XAxis?.font || "Arial", family: response.plotAxisTitleOptions?.XAxis?.font || "Arial"}, sizeFont: MAPPINGS.fontSizes[response.plotAxisTitleOptions?.XAxis?.fontSizeType as keyof typeof MAPPINGS.fontSizes] || MAPPINGS.fontSizes[14]},
      y2: {font: {name: response.plotAxisTitleOptions?.Y2Axis?.font || "Arial", family: response.plotAxisTitleOptions?.Y2Axis?.font || "Arial"}, sizeFont: MAPPINGS.fontSizes[response.plotAxisTitleOptions?.Y2Axis?.fontSizeType as keyof typeof MAPPINGS.fontSizes] || MAPPINGS.fontSizes[14]},
    }))
  }
  return response;
});

export const getCategory1TagAttributesValuesThunk = createAsyncThunk('sideBar/getCategory1TagAttributesValues', async (unit: number, thunkAPI) => {
  const response = await getCategory1TagAttributesValues(unit, thunkAPI);
  return response;
});

export const getProductionTagAttributesValuesThunk = createAsyncThunk('sideBar/getProductionTagAttributesValues', async (unit: number, thunkAPI) => {
  const response = await getProductionTagAttributesValues(unit, thunkAPI);
  return response;
});

export const getNumericTagAttributesValuesThunk = createAsyncThunk('sideBar/getNumericTagAttributesValues', async (unit: number, thunkAPI) => {
  const response = await getNumericTagAttributesValues(unit, thunkAPI);
  return response;
});

export const getQuickPlotsThunk = createAsyncThunk('sideBar/getQuickPlots', async (thunkAPI) => {
  const data = await getQuickPlots(thunkAPI)
  return data
})

export const saveQuickPlotThunk = createAsyncThunk('sideBar/saveQuickPlots', async (plotId: string, thunkApi) => {
  const data = await saveQuickPlot(plotId, thunkApi)
  return data
})

export const setQuickPlotThunk = createAsyncThunk('sideBar/setQuickPlot', async (payload: { workspaceId: string, plotId: string, quickPlotId: string, field?: 'histogramOptions' | 'plotOptions' }) => {
  const data = await setQuickPlot({ workspaceId: payload.workspaceId, plotId: payload.plotId, quickPlotId: payload.quickPlotId })
  return { data, plotId: payload.plotId, field: payload.field }
})

export const deleteQuickPlotThunk = createAsyncThunk('sideBar/deleteQuickPlot', async (plotId: string) => {
  await deleteQuickPlot(plotId)
  return plotId
})

export const saveSidebarThunk = createAsyncThunk('sideBar/saveSidebar', async (data: { plotOptions: PlotOptions, workspaceId: string, plotId: string }, thunkAPI) => {
  const response = await saveSidebar(data, thunkAPI);
  return response
})


export const T_saveSidebarBenchmark = createAsyncThunk('sideBar/saveSidebarBenchmark', async (data: { plotOptions: IBenchmarkPlotOptions, workspaceId: string, plotId: string }, thunkAPI) => {
  const response = await saveSidebarBenchmarkOptions(data, thunkAPI);
  return response
})


export const saveSidebarHistogramThunk = createAsyncThunk('sideBar/saveSidebarHistogram', async (data: { plotOptions: IHistogramOptions, workspaceId: string, plotId: string }, thunkAPI) => {
  const response = await saveSidebarHistogramOptions(data, thunkAPI);
  return response
})

export const selectQuickPlotThunk = createAsyncThunk('sideBar/selectQuickPlot', async (quickPlotId: string) => {
  const data = await selectQuickPlot(quickPlotId)
  return data
})

export const T_getProjectViewOptions = createAsyncThunk('plot/getprojectViewOptions', async (data: { workspaceId: string, plotId?: string }, thunkAPI,) => {
  const response = await R_getProjectViewOptions({ workspaceId: data.workspaceId, plotId: data.plotId }, thunkAPI)
  return response
});



export const T_getProductionPlotIndentifierOptions = createAsyncThunk('plot/getProductionPlotIndentifierOptions', async (data: { workspaceId: string, ids: string[] }, thunkAPI,) => {
  const response = await R_getProductionPlotIndentifierOptions(data, thunkAPI)
  return response
});

export const T_getProducrtionOptions = createAsyncThunk('plot/getProducrtionOptions', async (data: { workspaceId: string, plotId: string }, thunkAPI,) => {
  const response = await R_getProductionPlotOptions(data, thunkAPI)
  if(response.plotAxisTitleOptions){
    thunkAPI.dispatch(workspaceSlice.actions.setAxisStyleSettings({
      title: {font: {name: response.plotAxisTitleOptions?.Title?.font || "Arial", family: response.plotAxisTitleOptions?.Title?.font || "Arial"}, sizeFont: MAPPINGS.fontSizes[response.plotAxisTitleOptions?.Title?.fontSizeType as keyof typeof MAPPINGS.fontSizes] || MAPPINGS.fontSizes[18]},
      y: {font: {name: response.plotAxisTitleOptions?.YAxis?.font || "Arial", family: response.plotAxisTitleOptions?.YAxis?.font || "Arial"}, sizeFont: MAPPINGS.fontSizes[response.plotAxisTitleOptions?.YAxis?.fontSizeType as keyof typeof MAPPINGS.fontSizes] || MAPPINGS.fontSizes[14]},
      x: {font: {name: response.plotAxisTitleOptions?.XAxis?.font || "Arial", family: response.plotAxisTitleOptions?.XAxis?.font || "Arial"}, sizeFont: MAPPINGS.fontSizes[response.plotAxisTitleOptions?.XAxis?.fontSizeType as keyof typeof MAPPINGS.fontSizes] || MAPPINGS.fontSizes[14]},
      y2: {font: {name: response.plotAxisTitleOptions?.Y2Axis?.font || "Arial", family: response.plotAxisTitleOptions?.Y2Axis?.font || "Arial"}, sizeFont: MAPPINGS.fontSizes[response.plotAxisTitleOptions?.Y2Axis?.fontSizeType as keyof typeof MAPPINGS.fontSizes] || MAPPINGS.fontSizes[14]},
    }))
  }
  return response
});

export const T_saveProductionPlotOptions = createAsyncThunk('plot/saveProducrtionOptions', async (data: { productionPlotOptions: IProductionOptions, workspaceId: string, plotId: string }, thunkAPI,) => {
  const response = await R_saveProductionPlotOptions(data, thunkAPI)
  return response
});


export const T_addFilterset = createAsyncThunk('filters/addFilterset', async (data: { workspaceId: string, title: string }, thunkAPI,) => {
  const response = await R_addFilterSet(data, thunkAPI)
  return response
});

export const T_getFilterset = createAsyncThunk('filters/getFilterset', async (data: { workspaceId: string }, thunkAPI,) => {
  const response = await R_getFilterSets(data, thunkAPI)
  return response
});

export const T_deleteFilterset = createAsyncThunk('filters/deleteFilterset', async (data: { workspaceId: string, filterSetId: string }, thunkAPI,) => {
  const response = await R_deleteFilterSet(data, thunkAPI)
  return response
});

export const T_changeFilterset = createAsyncThunk('filters/changeFilterset', async (data: { workspaceId: string, filterSetId: string }, thunkAPI,) => {
  const response = await R_changeFilterSet(data, thunkAPI)
  return response
});
R_changeFilterSet