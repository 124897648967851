import React, { useMemo, useState, useEffect } from "react"
import { ITagAttribute, TreeViewDataItem } from "store/sidebar/types"
import { FILTER_TYPES } from "helpers/constants/filters"
import { FilterCheckbox } from "./Checkbox"
import { FilterDropDown } from "./DropDown"
import { FilterButton } from "./Button"
import { FilterInput } from "./Input"
import { FilterTitle } from "./Title"
import { FilterLine } from "./Line"
import { ItemRenderProps } from "@progress/kendo-react-treeview"
import { ITreeElementProps } from "helpers/types/tree"
import Filter from "components/Filter/Filter"
import { Button } from "@progress/kendo-react-buttons"

import { MapOptions } from "store/workspace/types"
import { LICENSE_TYPES, ROUTES } from "helpers/constants/app"

import "./TreeItem.scss"
import { IElementType } from "components/SideBarItems/types"
import { useAppDispatch, useAppSelector } from "hooks/redux"
import { useLocation } from "react-router-dom"
import { selectActiveUser } from "store/userState/selectors"
import axios, { CancelTokenSource } from "axios"
import { workspaceSlice } from "store/workspace/slice"
import { saveMapOptionsThunk } from "store/workspace/thunk"
import { getMapViewFilteredPointsThunk } from "store/mapView/thunk"
import { eyeIcon } from "@progress/kendo-svg-icons" 


const TreeItem = ({ ...props }: ItemRenderProps & { item: TreeViewDataItem }) => {
  const dispatch = useAppDispatch()
  const { tabs, mapOptions, workspaceId } = useAppSelector((state) => state.workspaceSlice)
  const selectedTab = tabs?.find((item) => item.isSelected)
  const location = useLocation()
  const deepCheckFilterApplied = (items: TreeViewDataItem[]): boolean => {
    const dropdownNames: (keyof typeof FILTER_TYPES)[] = [FILTER_TYPES.title, FILTER_TYPES.subMenu]
    if (!dropdownNames.includes(props.item.type as keyof typeof FILTER_TYPES)) return false
    if (!items.length) return false

    return items.some((item) => {
      if (item.type === "subMenu") return items.some((item: TreeViewDataItem["items"][number]) => item.filterApplied)
    })
  }
  const user = useAppSelector(selectActiveUser)
  const mapEndpointSource = React.useRef<CancelTokenSource | null>(null)
  const [shouldSaveValues, setShouldSaveValues] = useState<{ element?: IElementType; group?: string | null } | null>(null)

  const handleChangeValue = (obj: { element: IElementType; value: boolean; group: string | null; index?: number }): void => {
    dispatch(workspaceSlice.actions.setMapOptionItem(obj))
    setShouldSaveValues({ element: obj.element, group: obj.group })
  }

  const handleSaveValues = async (_?: IElementType, group?: string | null) => {
    await dispatch(saveMapOptionsThunk({ id: workspaceId, mapOptions }))
    if (group === "serverSideOptions") {
      if (mapEndpointSource.current) mapEndpointSource.current.cancel()
      mapEndpointSource.current = axios.CancelToken.source()
      await dispatch(getMapViewFilteredPointsThunk({ workspaceId, source: mapEndpointSource.current }))
    }
  }
  useEffect(() => {
    if (shouldSaveValues) {
      handleSaveValues(shouldSaveValues.element, shouldSaveValues.group)
      setShouldSaveValues(null)
    }
  }, [mapOptions, shouldSaveValues])

  const filterClassName = useMemo(() => {
    return deepCheckFilterApplied(props.item.items) ? "text-primary" : "text-secondary"
  }, [props.item.items])

  const COMPONENTS_BY_FILTER_TYPE: { [key in keyof typeof FILTER_TYPES]: React.FC<ITreeElementProps> } = {
    [FILTER_TYPES.title]: FilterTitle,
    [FILTER_TYPES.subMenu]: FilterTitle,
    [FILTER_TYPES.subTitle]: FilterTitle,
    [FILTER_TYPES.select]: FilterDropDown,
    [FILTER_TYPES.input]: FilterInput,
    [FILTER_TYPES.button]: FilterButton,
    [FILTER_TYPES.filter]: Filter,
    [FILTER_TYPES.numeric]: Filter,
    [FILTER_TYPES.checkbox]: FilterCheckbox,
    [FILTER_TYPES.line]: FilterLine,
  }
  const Component = COMPONENTS_BY_FILTER_TYPE[props.item.type as keyof typeof FILTER_TYPES]

  const buttonIdToElementMap: { [key: string]: keyof MapOptions } = {
    "0": "subsurfaceAssets",
    "1": "ccsProjects",
    "2": "cO2Emitters",
    "3": "globalPipelineNetworks",
  }

  const renderMapButtons = useMemo(() => {
    if (location.pathname.includes(ROUTES.explore)) {
      switch (selectedTab?.type) {
        case "mapView":
          return true
        default:
          return false
      }
    } else if (location.pathname.includes(ROUTES.dashboard) || location.pathname.includes(ROUTES.booking)) {
      return true
    } else {
      return false
    }
  }, [selectedTab, location.pathname])

  return (
    <>
      {props.item.type === FILTER_TYPES.title || props.item.type === FILTER_TYPES.subMenu || props.item.type === FILTER_TYPES.subTitle ? (
        <div
          onClick={() => props.item.handleExpandChange?.(props.item)}
          className={filterClassName}
          style={{ cursor: "pointer", display: "flex", justifyContent: "space-between", alignItems: "center" }}
        >
          <Component key={props.item.id} props={props} appliedFilterClassName={filterClassName} />
          {renderMapButtons && (["0"].includes(props.item.id) || (["1", "2", "3"].includes(props.item.id) && user.licenseType === LICENSE_TYPES.global)) ? (
            <Button
              className="no-margin-button"
              svgIcon={eyeIcon}
              style={{ marginLeft: "auto" }}
              themeColor={
                props.item.id === "0" && mapOptions.subsurfaceAssets
                  ? "primary"
                  : props.item.id === "1" && mapOptions.ccsProjects
                  ? "primary"
                  : props.item.id === "2" && mapOptions.cO2Emitters
                  ? "primary"
                  : props.item.id === "3" && mapOptions.globalPipelineNetworks
                  ? "primary"
                  : "light"
              }
              onClick={(event) => {
                event.stopPropagation()
                const currentValue = mapOptions[buttonIdToElementMap[props.item.id]]
                handleChangeValue({ element: buttonIdToElementMap[props.item.id], value: !currentValue, group: "serverSideOptions" })
              }}
            ></Button>
          ) : null}
        </div>
      ) : (
        <Component key={props.item.id} props={props} appliedFilterClassName={filterClassName} />
      )}
    </>
  )
}

export default React.memo(TreeItem)
