import "./SideBar.scss"
import "../../colorHelpers.scss"
import "assets/styles/commons.scss"
import React, { useCallback, useEffect, useMemo, useState, useRef } from "react"
import {
  handleTreeViewCheckChange,
  ItemRenderProps,
  processTreeViewItems,
  TreeView,
  TreeViewCheckChangeEvent,
  TreeViewCheckDescriptor,
  TreeViewExpandChangeEvent,
} from "@progress/kendo-react-treeview"

import { workspaceSlice } from "store/workspace/slice"
import { useAppDispatch, useAppSelector } from "../../hooks/redux"
import { saveMapOptionsThunk, T_editWorkspaceFilters } from "store/workspace/thunk"
import {
  getCategory1TagAttributesValuesThunk,
  getNumericTagAttributesValuesThunk,
  getProductionTagAttributesValuesThunk,
  getQuickPlotsThunk,
  getSideBarFiltersThunk,
  TH_GetAllTypes,
} from "store/sidebar/thunk"
import { combineClassNames, deepClone } from "helpers/functions/commons"
import { TreeViewDataItem } from "store/sidebar/types"
import { useLocation } from "react-router-dom"
import { selectAllTagAttributes } from "store/sidebar/selectors"
import { getMapViewFilteredPointsThunk } from "store/mapView/thunk"
import usePrevious from "hooks/usePrevious"
import { PlotOptions } from "components/PlotOptions/PlotOptions"
import { HistogramOptions } from "components/HistogramOptions/HistogramOptions"
import { MapOptions } from "components/mapOptions/MapOptions"
import { ROUTES, SCREEN_RESOLUTIONS } from "helpers/constants/app"
import { TH_GetCcsProjectNames } from "store/ccs/thunk"
import { IWorkspaceItem } from "store/workspace/types"
import { ProductionPlotOptions } from "components/productionPlotOptions/productionPlotOptions"
import { ProjectViewOptions } from "components/ProjectViewOptions/ProjectViewOptions"
import { FilterOptions } from "components/FilterOptions/FilterOptions"
import { BenchmarkOptions } from "components/benckmarkOptions/benchmarkOptions"
import { sideBarTreeSlice } from "store/sidebar/slice"
import { selectedStyleSettings } from "store/workspace/selectors"
import { T_getDatasetsList } from "store/userData/thunk"
import TreeItem from "./TreeItem"

const SideBar = () => {
  const dispatch = useAppDispatch()
  const styleSettings = useAppSelector(selectedStyleSettings)
  const { filtersTreeView, tree, tabs, workspaceId, workspaces, hiddenSidebar, filteredFilters, filters, plotId, mapOptions } = useAppSelector((state) => state.workspaceSlice)
  const { isFetched: isFetchedMap } = useAppSelector((state) => state.mapViewSlice)
  const tagAttributes = useAppSelector(selectAllTagAttributes)
  const { entityId } = useAppSelector((store) => store.workspaceSlice.userActions)
  const { pathname } = useLocation()
  const filtersSearchText = useAppSelector((state) => state.sideBarTreeSlice.filtersSearchText)
  const isSidebarOpen = useAppSelector((state) => state.sideBarTreeSlice.isSidebarOpen)
  const [check, setCheck] = useState<string[] | TreeViewCheckDescriptor>([])
  const [expand, setExpand] = useState<{ ids: string[]; idField: string }>({ ids: [""], idField: "" })
  const [filtersOpened, setFiltersOpened] = useState<boolean>(true)

  // const [isOpened, setIsOpened] = useState(true);

  const initialized = useRef(false)
  const initializedEntity = useRef(false)
  const initialWorkspaceUnit = useRef<number | null>(null)
  const selectedTab = tabs?.find((item) => item.isSelected)
  const location = useLocation()

  const handleExpandChange = (item: TreeViewDataItem) => {
    if ((filteredFilters.length && item.type === "title") || !tree) return
    const clonedTree = deepClone(tree)
    const ids = expand.ids.slice()
    const index = ids.indexOf(item.id)
    index === -1 ? ids.push(item.id) : ids.splice(index, 1)
    setExpand({ ids, idField: "id" })
    const node = clonedTree.find((node: TreeViewDataItem) => node.id === item.id)
    if (node) {
      node.expanded = !item?.expanded
    }

    dispatch(workspaceSlice.actions.setTree(clonedTree))
  }

  const onExpandChange = (event: TreeViewExpandChangeEvent) => {
    handleExpandChange(event.item)
  }

  const onCheckChange = (event: TreeViewCheckChangeEvent) => {
    const settings: {
      singleMode: boolean
      checkChildren: boolean
      checkParents: boolean
    } = {
      singleMode: false,
      checkChildren: false,
      checkParents: false,
    }
    setCheck(handleTreeViewCheckChange(event, check, structuredClone(tree), settings))
  }

  

  

  useEffect(() => {
    const initialize = async () => {
      const workspace = workspaces.find(workspace => workspace.id === workspaceId)
      initialized.current = true
      initialWorkspaceUnit.current = workspace?.unit ?? 1
      await dispatch(getSideBarFiltersThunk({ tagAttributes }))
      dispatch(getCategory1TagAttributesValuesThunk(workspace?.unit ?? 1))
      dispatch(getProductionTagAttributesValuesThunk(workspace?.unit ?? 1))
      dispatch(getNumericTagAttributesValuesThunk(workspace?.unit ?? 1))
      await dispatch(getQuickPlotsThunk())
    }
    if (entityId !== "" && !initializedEntity.current) {
      initializedEntity.current = true
      if (entityId) {
        const tab = tabs.find((item) => item.id === entityId) || tabs.find((item) => item.isSelected) || tabs.find((item) => item.type === "mapView")
        tab && dispatch(workspaceSlice.actions.setSelectedTab(tab))
        dispatch(TH_GetCcsProjectNames({ workSpaceId: workspaceId, selectedId: entityId }))
      } else {
        const tab = tabs.find((item) => item.isSelected) || tabs.find((item) => item.type === "mapView")
        tab && dispatch(workspaceSlice.actions.setSelectedTab(tab))
      }
    }

    if (tabs?.length && !initialized.current && workspaceId && workspaces?.length && tagAttributes?.length) {
      initialize()
    }
  }, [tabs?.length, workspaceId, workspaces, entityId, tagAttributes?.length])

  useEffect(() => {
    ;(async () => {
      await dispatch(getSideBarFiltersThunk({ tagAttributes }))
      await dispatch(getQuickPlotsThunk())
      const workspace = workspaces.find(workspace => workspace.id === workspaceId)
      if (workspace && initialWorkspaceUnit.current !== null && initialWorkspaceUnit.current !== workspace.unit) {
        dispatch(getNumericTagAttributesValuesThunk(workspace?.unit ?? 1))
        initialWorkspaceUnit.current = workspace.unit
      }
    })()
  }, [workspaceId])

  useEffect(() => {
    if (!tree.length) {
      dispatch(workspaceSlice.actions.setTree([...filtersTreeView]))
    }
  }, [tree])

  const { filterOptions, categorizeOptions } = filters
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const timeoutId = useRef<any>(null)

  useEffect(() => {
    if (!isFetchedMap && workspaceId) {
      dispatch(getMapViewFilteredPointsThunk({ workspaceId }))
    }
  }, [isFetchedMap, workspaceId])

  const prevWorkspaceId = usePrevious(workspaceId)

  useEffect(() => {
    if (workspaceId && prevWorkspaceId === workspaceId) {
      const foundWorkspace = workspaces.find((item: IWorkspaceItem) => item.id === workspaceId)
      if (!foundWorkspace) return
      if (timeoutId.current) clearTimeout(timeoutId.current)
      timeoutId.current = setTimeout(() => {
        ;(async () => {
          await dispatch(T_editWorkspaceFilters({ id: workspaceId, plotId, title: foundWorkspace?.title, unit: foundWorkspace?.unit, mapOptions: tree[2], filterOptions, categorizeOptions }))
          isFetchedMap && (await dispatch(getMapViewFilteredPointsThunk({ workspaceId })))
        })()
      }, 100)
    }
  }, [filterOptions, categorizeOptions, workspaceId])

  useEffect(() => {
    if (!tagAttributes?.length) {
      dispatch(TH_GetAllTypes())
    }
  }, [tagAttributes])

  useEffect(() => {
    dispatch(T_getDatasetsList())
  }, [])

  const toggleSidebar = () => dispatch(sideBarTreeSlice.actions.setSidebarOpen(!isSidebarOpen))

  useEffect(() => {
    if (window.innerWidth < SCREEN_RESOLUTIONS.LAPTOP) {
      dispatch(sideBarTreeSlice.actions.setSidebarOpen(false))
    }
  }, [])

  const renderOptions = useMemo(() => {
    if (location.pathname.includes(ROUTES.explore)) {
      switch (selectedTab?.type) {
        case "mapView":
          return <MapOptions />
        case 1:
          return <PlotOptions />
        case 2:
          return <HistogramOptions />
        case 6:
          return <ProductionPlotOptions />
        case 5:
          return <ProjectViewOptions />
        case 7:
          return <BenchmarkOptions />
        default:
          return null
      }
    } else if (location.pathname.includes(ROUTES.dashboard) || location.pathname.includes(ROUTES.booking)) {
      return <MapOptions />
    }
  }, [selectedTab, location.pathname])

  const activeTree = useMemo(() => (filteredFilters.length ? filteredFilters : tree), [filteredFilters, tree])

  const handleToggleSidebar = useCallback(() => {
    toggleSidebar()
  }, [toggleSidebar])

  return location.pathname.includes("home") ? null : (
    <div className="sticky-area">
      <div className={combineClassNames(["side-bar", isSidebarOpen ? "opened" : "closed"])}>
        <button className="chevron-left-btn" onClick={handleToggleSidebar} />
        {
          // (isSidebarOpen && !hiddenSidebar) &&
          <div className="sidebarContent">
            {filteredFilters.length || tree.length ? (
              <>
                <div className="filters">
                  <div className="contentVisibilityHandler">
                    <h3 onClick={() => setFiltersOpened(!filtersOpened)} style={{ cursor: "pointer" }}>
                      <span className={filtersOpened ? "k-icon k-i-caret-alt-down" : "k-icon k-i-caret-alt-right"}></span>Filter Options
                    </h3>
                  </div>
                  <div className={filtersOpened ? "filterContent" : "filterContent closed"}>
                    <div className="filterOptions">
                      <FilterOptions searchValue={filtersSearchText} />
                    </div>
                    <TreeView
                      data={processTreeViewItems(activeTree[0].items, {
                        check: check,
                        expand: expand,
                      }).map((item) => ({
                        ...item,
                        handleExpandChange: () => handleExpandChange(item),
                      }))} // eslint-disable-line
                      expandIcons={true}
                      // className={`${tree[0].expanded ? 'filtersTree' : 'filtersTreeClosed'} ${filteredFilters.length ? 'filteredFilters' : ''}`}
                      onExpandChange={onExpandChange}
                      onCheckChange={onCheckChange}
                      item={TreeItem}
                    />
                  </div>
                </div>
                {renderOptions}
              </>
            ) : (
              ""
            )}
          </div>
        }
      </div>
    </div>
  )
}

export default React.memo(SideBar)
