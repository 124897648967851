import React, { useEffect, useState } from "react";
import './DatePicker.scss';
import { IElementType } from "./types";
import { DatePicker, DatePickerChangeEvent } from "@progress/kendo-react-dateinputs";
import { Button } from "@progress/kendo-react-buttons";
import { calendarIcon, cancelIcon } from '@progress/kendo-svg-icons';
import { SvgIcon } from "@progress/kendo-react-common"
import moment from 'moment'

interface IInputCharactersCounterProps {
    label?: string;
    element: IElementType;
    group?: string | null;
    value: string | null;
    type?: string;
    disabled?: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setFunction: (args: any) => any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    saveFunction: (element?: IElementType, group?: string | null) => any;
}

export const WrappedDropDown = ({ label, element, group, value, setFunction, saveFunction }: IInputCharactersCounterProps) => {
    const [changed, setChanged] = useState<boolean>(false);

    const handleChange = (e: DatePickerChangeEvent) => {
        if (!e.value) return null
        setFunction({ group, element, value: moment(e.value).format('YYYY-MM-DD') });
        setChanged(true);
    };

    const handleRemoveValue = () => {
        setFunction({ group, element, value: null });
        setChanged(true);
    };

    useEffect(() => {
        if (changed) {
            saveFunction(element, group);
        }
        setChanged(false);
    }, [value]);

    return (
      <div className="datePickerContainer">
        <DatePicker
          className={"datePicker"}
          label={label}
          value={value ? new Date(value) : null}
          onChange={handleChange}
          format="dd/MMM/yyyy"
          weekNumber={true}
          calendar={() => <SvgIcon icon={calendarIcon} />}
        />
        <Button disabled={!value} className="iconButton cancelIcon" onClick={() => handleRemoveValue()} svgIcon={cancelIcon} title="Save" />
      </div>
    )
};
