import './Filter.scss'
import Loading from '../Loading/Loading';
import FilterConditions from '../FilterConditions/FilterConditions';
import React, { FC, useEffect, useRef, useState } from 'react';
import { ICondition, ITagAttributeAllowedValueItem, ITagAttributeAllowedValue, ITagAttribute } from 'store/sidebar/types';
import { GetTagAttributeAllowedValuesThunk } from '../../store/sidebar/thunk';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { Checkbox, CheckboxChangeEvent } from '@progress/kendo-react-inputs';
import { workspaceSlice } from 'store/workspace/slice';
import { v4 as uuidv4 } from 'uuid';
import { ITreeElement } from 'helpers/types/tree';
import { Button } from '@progress/kendo-react-buttons';
import { trackGAEvent } from 'helpers/functions/GoogleAnalytics/GA';
import { eventsGA } from 'helpers/functions/GoogleAnalytics/types';
import { sideBarTreeSlice } from 'store/sidebar/slice';
import { selectAllTagAttributes } from "store/sidebar/selectors"
import { filterIcon } from "@progress/kendo-svg-icons" 

interface IProps {
    props: ITreeElement;
};

const Filter: FC<IProps> = ({ props }) => {
    const dispatch = useAppDispatch();
    const { filters, isEnabled: isFiltersEnabled } = useAppSelector(state => state.workspaceSlice.filters.filterOptions);
    const [open, setOpen] = useState<boolean>(false);
    const [bottom, setBottom] = useState<boolean>(false);
    const [firstConditionInputValue, setFirstConditionInputValue] = useState<string>();
    const [secondConditionInputValue, setSecondConditionInputValue] = useState<string>();
    const [firstConditionListValue, setFirstConditionListValue] = useState<ICondition>();
    const [secondConditionListValue, setSecondConditionListValue] = useState<ICondition>();
    const [logicalOperationListValue, setLogicalOperationListValue] = useState<ICondition>();
    const filtersSearchText = useAppSelector(store=>store.sideBarTreeSlice.filtersSearchText)
    const filtersSearched = !!filtersSearchText
    const { loadAllowedValues, tagAttributeAllowedValues, workspaceId } = useAppSelector(state => state.workspaceSlice);
    const filtersDropDownRef = useRef() as React.MutableRefObject<HTMLInputElement>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const filterButtonRef = useRef() as React.MutableRefObject<any>;
    const tagAttributes = useAppSelector(selectAllTagAttributes)

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleFilterButton = (e: any) => {
        e.screenY < 690 ? setBottom(true) : setBottom(false);
        dispatch(GetTagAttributeAllowedValuesThunk({ tagAttributeId: props.item.id, filters, workspaceId, props }));
        setOpen(!open);
    }

    useEffect(()=>{
        if(open){
            setFirstConditionInputValue(props?.item?.firstConditionInput || "")
            setSecondConditionInputValue(props?.item?.secondConditionInput || "") 
            setFirstConditionListValue(props?.item?.firstConditionList || { id: 5, name: "Is greater than", defaultPriority: 1 })
            setSecondConditionListValue(props?.item?.secondConditionList || { id: 3, name: "Is less than", defaultPriority: 1 })
            setLogicalOperationListValue(props?.item?.logicalOperationList || { id: 1, name: "And", defaultPriority: 1 })
        }
    }, [open, props?.item])


    const handleCheckbox = (e: CheckboxChangeEvent, filter: ITagAttributeAllowedValueItem, id: string) => {        
        dispatch(workspaceSlice.actions.checkFilter({ value: e.value, filter, id, props, filtersSearched }));
    };

    const applyFilter = () => {
        dispatch(sideBarTreeSlice.actions.setSelectedFilterSet(null))
        dispatch(workspaceSlice.actions.changeNumberInputValue({ value: firstConditionInputValue, props, input: 'firstConditionInput', filtersSearched }));
        dispatch(workspaceSlice.actions.changeNumberInputValue({ value: secondConditionInputValue, props, input: 'secondConditionInput', filtersSearched }));
        if (!isFiltersEnabled) {
            dispatch(workspaceSlice.actions.clearAllFilters());
            dispatch(workspaceSlice.actions.clearFiltersApplied());
            dispatch(workspaceSlice.actions.enableDisableFilters());
        };

        dispatch(workspaceSlice.actions.applyFilter({
            props: {
                ...props, item: {
                    ...props.item,
                    firstConditionInput: firstConditionInputValue,
                    secondConditionInput: secondConditionInputValue,
                    firstConditionList: firstConditionListValue,
                    secondConditionList: secondConditionListValue,
                    logicalOperationList: logicalOperationListValue
                }
            },
            filtersSearched
        }));

        const eventObj: any = {filter: props.item.name, value: props.item.values?.join()}
        let filterLogicalCondition = ''
        if(firstConditionInputValue){
            filterLogicalCondition = `${props.item.firstConditionList?.name} ${firstConditionInputValue}`
        } 
        if(firstConditionInputValue && secondConditionInputValue){
            filterLogicalCondition += ` ${props.item.logicalOperationList?.name}`
        }
        if(secondConditionInputValue){
            filterLogicalCondition += ` ${props.item.secondConditionList?.name} ${secondConditionInputValue}`
        }
        if(filterLogicalCondition){
            eventObj.filterLogicalCondition = filterLogicalCondition
        }
        trackGAEvent(eventsGA.filtered, eventObj)
    };

    const getFilters = async () => {
        setTimeout(async () => {
            await dispatch(GetTagAttributeAllowedValuesThunk({ tagAttributeId: props.item.id, filters, workspaceId, props }))
        }, 200)
    }

    const clearFilter = async () => {
        await dispatch(workspaceSlice.actions.clearFilter({ props, filtersSearched }));
        await getFilters()
    };

    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const handleClickOutside = (event: any) => {
            if (
                filtersDropDownRef.current &&
                !filtersDropDownRef.current.contains(event.target) &&
                filterButtonRef.current &&
                !filterButtonRef.current.element.contains(event.target) &&
                !event.target.classList.contains('k-list-item-text') &&
                !event.target.classList.contains('k-list-item')
            ) {
                setOpen(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [filtersDropDownRef]);

    const inputs = {
        firstConditionInputValue, setFirstConditionInputValue,
        secondConditionInputValue, setSecondConditionInputValue,
        firstConditionListValue, setFirstConditionListValue,
        secondConditionListValue, setSecondConditionListValue,
        logicalOperationListValue, setLogicalOperationListValue
    }

    const tagAttribute = tagAttributes.find((f: ITagAttribute) => f.id === props.item.id)
    const unit = tagAttribute?.unitOfMeasurement || ''

    return (
        <>
            <div className={props.item.type}>
                <div className='titleContainer' title={`${props.item.tooltip || ''}${unit ? ' [' + unit + ']' : ''}`}>
                    <span className='title'>{props.item.name} {`${unit ? ' (' + unit + ')' : ''}`}</span>
                    <Button svgIcon={filterIcon} themeColor={props.item.filterApplied ? 'primary' : 'light'} onClick={(e) => { handleFilterButton(e) }} ref={filterButtonRef} />
                </div>
                <div className={open ? `FiltersDropDown ${bottom && 'bottom'}` : `FiltersDropDown closed ${!bottom && 'false'}`} ref={filtersDropDownRef} onClick={(e) => e.preventDefault()}>
                    <div className='checkboxes'>
                        {
                            loadAllowedValues ? <Loading /> :
                                tagAttributeAllowedValues.find((f: ITagAttributeAllowedValue) => f.tagId === props.item.id)?.values.map((val: ITagAttributeAllowedValueItem) =>
                                    <div className="filterCheckboxBlock" key={uuidv4()}>
                                        <Checkbox checked={val.checked} onChange={(e) => handleCheckbox(e, val, props.item.id)} />
                                        <label>{val.text}</label>
                                    </div>)
                        }
                    </div>
                    <div className="filterInputsBlock">
                        <h3>Show rows with a value that</h3>
                        <FilterConditions props={props} inputs={inputs} />
                        <div className="actions">
                            <Button themeColor={'primary'} className="filter" onClick={applyFilter}>Filter</Button>
                            <Button themeColor={'primary'} className="clearFilter" onClick={clearFilter}>Clear Filter</Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

export default React.memo(Filter)
